h1 {
  font-size: 24px;
}

.loginWrapper h1 {
  color: #fff;
}

.loginWrapper {
  min-height: 100vh;
  background-color: #2f4047;
}

.login-outer {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  max-width: 650px;
  width: 90%;
  box-shadow: 0px 7px 65px 0px rgba(0, 0, 0, 0.07);
}
.main-heading {
  color: #fff;
  margin-top: 50px;
}

.page-title {
  padding: 0 0 10px;
}
.btn-end {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 15px;
}

.plr36 {
  padding-left: 36px;
  padding-right: 36px;
}

.body-wrapper {
  margin: 0 36px 36px;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
    0px 2px 2px rgba(159, 162, 191, 0.32);
  border-radius: 10px;
}
.body-wrapper-not-found {
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 10px;
}

.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
}

.page-header {
  max-width: 100% !important;
  padding: 0 !important;
}

.common-table {
  border-radius: 10px 10px 0 0 !important;
}

img {
  max-width: 100%;
  height: inherit;
}

.MuiTableCell-root {
  white-space: nowrap;
}

.baitactive,
.baitinactive,
.baitnone {
  min-width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  font-weight: bold;
}
.baitVal {
  font-size: 14px;
}
.baitVal span {
  margin-left: 5px;
}
.baitnone {
  background-color: transparent;
  color: #000;
}
.baitactive {
  background-color: #539165;
}
.baitinactive {
  background-color: #ccc;
}
.change-pass-form {
  max-width: 650px;
  width: 90%;
}

button[type='submit'].MuiButtonBase-root,
button[type='submit'].MuiButtonBase-root:hover {
  background-color: #008f9c;
}

/* button.MuiButton-text, .btn-end button {
  padding: 10px 20px;
  border-radius: 50px;
} */

.info-outer {
  display: inline-flex;
  align-items: center;
}
.info-outer.activeInfo {
  margin-right: 10px;
}
.info-outer span {
  padding-left: 5px;
}

button.MuiButtonBase-root.Mui-disabled {
  background-color: rgba(110, 117, 159, 0.1);
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  margin-top: 5px !important;
}

.table-pagination-inner {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 5px 15px;
  gap: 10px;
  flex-wrap: wrap;
}

select {
  border: 0;
  font-size: 14px;
  color: #2f4047;
  border-radius: 10px;
  padding: 5px 10px;
  background: #fff url('../../public/static/images/arrow.png') no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  background-size: 10px;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  outline: none;
  font-weight: 500;
}
.lang-dropdown {
  position: relative;
  cursor: pointer;
}
.lang-dropdown .MuiSvgIcon-root {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 20px;
}
.lang-dropdown select {
  height: 42px;
  margin-right: 10px;
  padding-left: 40px;
  cursor: pointer;
}

.not-found {
  text-align: center;
  padding: 0 15px;
  color: rgba(34, 51, 84, 0.7);
}

.bait-header {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
}
.bait-header span {
  margin-right: 5px;
}
.flex-start-item {
  justify-content: flex-start !important;
}

.detail-pest img {
  position: relative;
  top: 3px;
  margin-right: 4px;
}

.MuiButtonBase-root.loading-button,
.MuiButtonBase-root.loading-button:hover {
  background-color: #444;
  color: #fff !important;
}

.loading-button:disabled {
  background-color: #eaeaea !important;
  color: #444 !important;
}

.loading-button:disabled span {
  color: #444 !important;
}

.box-detail {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.detail-header {
  gap: 20px;
}
.cus-info {
  display: flex;
  flex-direction: column !important;
}

.cus-info h5 {
  margin: 0;
  font-size: 14px;
}
.cus-info span {
  font-size: 12px;
  font-weight: normal;
}

.pt-0 {
  padding-top: 0 !important;
}

.password-reset {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

@media screen and (max-width: 991px) and (orientation: landscape) {
  .loginWrapper {
    min-height: auto;
  }
}

@media screen and (max-width: 767px) {
  .body-wrapper {
    margin: 0 36px 20px;
    position: relative;
  }
  .MuiPageTitle-wrapper.pest-header {
    margin-bottom: 20px !important;
  }
  .plr36 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.pest-address {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
}

.pest-address h4 {
  margin: 0 0 5px 0;
  padding: 0;
}
.pest-address h5 {
  font-size: 12px;
}
.pest-address .street-address {
  max-width: 300px;
  display: inline-block;
}
.MuiSvgIcon-root.green-status {
  fill: #42c54f;
}
.MuiSvgIcon-root.gray-status {
  fill: #858585;
}
.MuiSvgIcon-root.red-status {
  fill: #ff5020;
}
.MuiSvgIcon-root.yellow-status {
  fill: yellow;
}

.sidebar-logo {
  text-align: center;
  padding: 5px 10px;
}

.switch-toggle .PrivateSwitchBase-input {
  left: -12px !important;
  width: 40px !important;
}

.switch-toggle .MuiSwitch-switchBase {
  padding: 7px !important;
}

table th {
  text-transform: capitalize !important;
}

.phone-input {
  margin-top: 16px !important;
  margin-bottom: 8px !important;
}
.MuiDialogTitle-root.modal-heading {
  font-weight: bold;
  font-size: 18px;
}

.setPass .MuiInputBase-fullWidth {
  padding-right: 0 !important;
}

.common-table th svg {
  opacity: 0;
}
.common-table th .sort-btn svg {
  opacity: 1;
}
.common-table th:hover svg {
  opacity: 1;
}

.sort-btn {
  background-color: transparent;
  border: none;
  outline: none;
  width: 14px;
  height: 14px;
  padding: 0;
  margin: 0 0 0 5px;
  position: relative;
  top: 2px;
}

.MuiTableCell-head:hover .table-sort {
  display: block;
}

.cur-pointer {
  cursor: pointer;
}

.tooltip-table td.tooltip-col {
  width: 250px;
  max-width: 250px;
}
.tooltip-table td .table-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.textarea-mui {
  padding: 16.5px 14px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  width: 100%;
  border-radius: 10px;
  resize: none;
}
.textareaInput-error {
  border: 1px solid #ff1943;
}
.textarea-error {
  padding: 10px 0;
  word-break: break-all;
  color: #ff1943;
  font-size: 13px;
  font-weight: bold;
}

.editable-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.editable-table-header {
  display: flex;
  background-color: rgba(34, 51, 84, 0.02);
  border-bottom: 1px solid rgba(34, 51, 84, 0.1);
}
.editable-header-cell {
  flex: 1;
  padding: 11px;
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  color: rgba(34, 51, 84, 0.7);
}
.editable-header-cell:nth-child(1),
.editable-table-cell:nth-child(1) {
  flex: 0 0 70px;
}
.editable-header-cell:nth-child(2),
.editable-table-cell:nth-child(2) {
  flex: 0 0 84px;
}
.editable-table.full-width-table .editable-header-cell:nth-child(1),
.editable-table.full-width-table .editable-table-cell:nth-child(1),
.editable-table.full-width-table .editable-header-cell:nth-child(2),
.editable-table.full-width-table .editable-table-cell:nth-child(2) {
  flex: 1;
}

.editable-table-cell input:focus,
.editable-table-cell input:focus-visible,
.editable-table-cell input:focus-within {
  border-color: transparent;
  outline: none;
}
.table-cell-position {
  position: relative;
  width: 100%;
}
.table-cell-inputReq {
  position: absolute;
  bottom: 2px;
  left: 10px;
  font-size: 11px;
  color: #ff1943;
}
.editable-table-content {
  overflow-x: auto;
  overflow-y: auto;
}
.editable-table-row {
  display: flex;
  border-bottom: 1px solid #ccc;
}
.editable-table-cell {
  flex: 1;
  padding: 11px;
  text-align: left;
  display: flex;
  align-items: center;
}
.editable-table-cell input {
  padding: 0;
  border-radius: 5px;
  width: 100%;
  border: 0;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.editable-table-cell.clickable-div span.cur-pointer {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  width: 165px;
}

.editable-table-cell input[type='checkbox'] {
  width: auto;
}
.editable-table-cell input.border1 {
  border: 1px solid #ccc;
  padding: 15px 10px;
}
.editable-table-cell input.border0 {
  border-color: transparent;
  padding: 15px 0px;
  pointer-events: none;
}
.editable-table-cell input.border1.error {
  border-color: #ff1943;
}
.editable-table-cell button[type='submit'] {
  background-color: darkgreen;
  color: #fff;
}

.error-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: #ffffff;
}

.error-modal ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.error-modal ul > li {
  color: red;
  min-height: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0.3rem;
}

.baitbox_action_icons {
  margin-right: 5px !important;
}

.table-head-new {
  width: 100%;
  display: flex !important;
}

.table-head-new .table-cell-wrap {
  width: 33.33%;
  flex: none !important;
}

.edit-body {
  width: 100% !important;
  display: flex !important;
  flex: none !important;
}

.clickable-div {
  cursor: pointer;
}

.body-cell {
  width: 33.33% !important;
  flex: none !important;
}

@media (max-width: 767px) {
  .editable-table-content {
    max-height: none;
  }
  .editable-header-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

@media screen and (max-width: 991px) {
  .detail-header {
    flex-direction: column;
  }
  .pest-address .street-address {
    max-width: 100%;
  }
  .pest-address .cus-info:first-child {
    max-width: 100%;
  }
}
@media screen and (min-width: 1201px) {
  .pest-address {
    justify-content: flex-end;
  }
}

.header-row-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.search-term-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  max-width: 300px;
}
.search-term-container .form-control {
  height: 40px;
  padding: 5px 32px 5px 15px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgba(34, 51, 84, 0.5);
  color: #223354;
  outline: none;
  border-radius: 10px;
}
.search-term-container .form-control::placeholder {
  color: #223354;
}
.search-term-container button.MuiButtonBase-root {
  padding: 2px;
  min-width: auto;
  margin: 0;
  position: absolute;
  right: 10px;
}
.search-term-container button.MuiButtonBase-root span {
  margin: 0;
}

table th {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-action #demo-select-small-label[data-shrink='true'] {
  top: 9px;
}

#demo-select-small-label.MuiInputLabel-root.Mui-focused {
  top: 20px;
  font-size: 18px;
  color: rgba(34, 51, 84, 0.7);
}

.checkbox-action .MuiOutlinedInput-root {
  height: 40px;
}
.filter-search-container {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.filter-outer button,
.filter-outer button:hover {
  height: 40px;
  font-size: 16px;
  background-color: #2f4047;
  color: #fff;
  margin-right: 0 !important;
}
.filter-outer button svg {
  font-size: 18px;
  margin-right: 5px;
}
.filter-table-head .editable-header-cell:nth-child(1),
.filter-table-body .editable-table-cell:nth-child(1) {
  flex: 0 0 200px;
}
.filter-table-head .editable-header-cell:nth-child(2),
.filter-table-body .editable-table-cell:nth-child(2) {
  flex: 1;
}
.filter-table-head .editable-header-cell,
.filter-table-body .editable-table-cell {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.filter-table-body .editable-table-cell p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.MuiDialogContent-root.filter-modal {
  display: flex;
  padding-bottom: 0;
}
.filter-modal-head {
  flex: 1;
  padding: 0 0 10px 0;
  background-color: rgba(110, 117, 159, 0.2);
  min-width: 170px;
}
.filter-modal-head:nth-child(2) {
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
}
.filter-modal-head h1 {
  font-size: 16px;
  border-bottom: 2px solid #fff;
  padding: 0 14px 10px;
}
.filter-modal-head .filter-item {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 0 10px;
}
.filter-modal-head .filter-item:last-child {
  margin-bottom: 0;
}
.filter-modal-btn {
  margin: 10px;
}
.filter-item-name {
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
}
.not-allowed {
  cursor: not-allowed;
}
@media screen and (max-width: 615px) {
  .MuiDialogContent-root.filter-modal {
    flex-direction: column;
  }
  .filter-modal-head:nth-child(2) {
    border: 0px;
    padding-bottom: 0;
  }
}
input[type='checkbox']:checked {
  accent-color: #539165;
}
input[type='checkbox'] {
  cursor: pointer;
}
input[type='checkbox']:disabled {
  cursor: not-allowed;
}
.filter-label-item {
  display: flex;
  gap: 5px;
  cursor: pointer;
}
.date-filter-logs {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.date-filter-logs label {
  font-size: 14px;
  padding-right: 10px;
}
.date-filter-logs input[type='date'] {
  height: 42px;
  padding: 5px 10px;
  background-color: #6e759f1a;
  border-radius: 10px;
  border: none;
  outline: none;
}
.date-filter-logs .apply-btn button {
  background-color: #539165;
}
.assign-tag-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  max-width: 300px;
}

@media screen and (max-width: 1780px) {
  .inventory-outer,
  .filter-container {
    overflow-x: auto;
    overflow-y: auto;
  }
  .inventory-outer .editable-table-header,
  .filter-container .filter-table-head {
    width: 1600px;
  }
  .inventory-outer .editable-table-content,
  .filter-container .filter-table-body {
    width: 1600px;
  }
}

.reset-password-link {
  color: #008f9c;
  font-weight: 500;
  text-decoration: none;
  text-align: right;
}

.MuiOutlinedInput-notchedOutline {
  border-width: 2px !important;
  border-color: #e6e6e6 !important;
}
.MuiOutlinedInput-root {
  border-radius: 5px !important;
}
.Mui-error {
  font-weight: normal !important;
}
.MuiFormControl-root.header-color {
  position: relative;
  border-radius: 5px !important;
  overflow: hidden;
}
.MuiFormControl-root.header-color input {
  border: 2px solid #e6e6e6;
}
.MuiFormControl-root.header-color label {
  font-size: 14px !important;
}

.color-modal {
  position: absolute;
  z-index: 99;
}
.MuiFormControl-root.logo-update {
  margin-top: 16px;
  margin-bottom: 8px;
}
.inner-box-wrapper {
  min-height: calc(100vh - 80px);
}
.inner-box-wrapper h1 {
  margin: 0;
}
.inner-box-wrapper form {
  margin-top: 20px;
}

.MuiFormControl-root.header-color .MuiFilledInput-underline {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.MuiFormControl-root.header-color .MuiFilledInput-underline:after,
.MuiFormControl-root.header-color .MuiFilledInput-underline:before {
  display: none !important;
}
.logo-retailers {
  padding: 5px;
  background-color: rgb(0, 0, 0, 0.1);
  display: inline-block;
  border: 1px solid #ccc;
}
.logo-retailers img {
  max-height: 70px;
  display: block;
}
.logo-retailers-edit {
  padding: 5px;
  background-color: rgb(0, 0, 0, 0.1);
  display: inline-block;
  border: 1px solid #ccc;
}
.logo-retailers-edit img {
  max-width: 160px !important;
  display: block;
}
.retailer-modal.MuiBox-root {
  background-color: #f7f7fe;
  border-radius: 10px;
  border: none;
}
.retailer-modal h2 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
}
.top-header.MuiBox-root {
  background-color: #2f4047;
  padding: 0 36px;
  @media screen and (max-width: 575px) {
    padding: 0 15px;
  }
}
button.logout.MuiButtonBase-root {
  background-color: #fff;
}
.top-header img {
  max-width: 80px;
  display: block;
}

.top-subHeader {
  padding-left: 0;
  padding-right: 0;
}
.top-header-btns.MuiBox-root {
  margin: 0;
}
.edit-reatiler-logo-text {
  color: rgba(34, 51, 84, 0.7);
  font-size: 75%;
  margin: 0 0 5px 0;
}

@media screen and (max-width: 575px) {
  .MuiPageTitle-wrapper.pest-header {
    padding: 15px;
  }
  .body-wrapper {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.link-wrapper {
  text-align: center;
}

.qr-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
