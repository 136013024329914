.loader-container {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  border-radius: 10px;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #2f4047;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
  margin-top: -20px;
  margin-left: -20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
